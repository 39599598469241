import React, { useState, useEffect, useRef, useMemo } from "react";
import { TextInput } from "../../components/TextInput";
import DropdownInput from "../../components/Dropdown";
import "../../styles/pages/users/addEditUser.scss";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { listProjects, getUser } from "../../graphql/queries";
import { listUsers } from "../../helpers/manualQueries";
import {
  createUser,
  updateUser,
  createUserProject,
  deleteUserProject,
  createEvent,
} from "../../graphql/mutations";
import {
  createUserInCognito,
  addUserToGroup,
  removeUserFromGroup,
  resetUserCredentials,
} from "../../service/AuthService";
import PropTypes from "prop-types";
import { Row, Col, Modal } from "react-bootstrap";
import { IoMdSearch } from "react-icons/io";
import { ButtonPrimary } from "../../components/ButtonsPrimary";
import translate from "../../i18n/translate";
import { BiX } from "react-icons/bi";
import _, { set } from "lodash";
import { ButtonSecondary } from "../../components/ButtonSecondary";

const AddEditUser = ({ onHide, modal, projectList, userRole, currentItem }) => {
  const { editUser, id, show } = modal;

  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [showAddToBaucamCloud, setShowAddToBaucamCloud] = useState(false);
  const [isErrorEdit, setIsErrorEdit] = useState(false);

  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    role: "",
    language: "",
    groupName: "",
  });
  const [projects, setProjects] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [oldRole, setOldRole] = useState("");
  const [searchProject, setSearchProject] = useState("");
  const events = {
    log: "",
    user: Auth.user.username,
    expire: "",
    logType: "user",
  };
  const getUserFunc = useRef({});
  const [existingId, setExistingId] = useState(null);
  const [errorMessageExisting, setErrorMessageExisting] = useState(false);
  const [existingEdisUser, setExistingEditUser] = useState();

  let now = (Date.now() + 15552000000) / 1000;
  events.expire = Math.round(now);

  // Function to handle "Add to BAU.CAMERA Cloud" click
  const handleAddToBaucamCloud = () => {
    setSelectedProjects([]); // Reset selected projects
    setFieldsDisabled(true); // Disable fields
    setShowAddToBaucamCloud(true); // Show the "Add to BAU.CAMERA Cloud" modal
    // Update projects to set selected to false
    setErrorMessageExisting(false);
    const updatedProjects = projects.map((project) => ({
      ...project,
      selected: false,
    }));

    // Assuming you have a setProjects function to update the state
    setProjects(updatedProjects);
  };

  const roleOptions =
    userRole === "superuser"
      ? [translate("client-all"), translate("client-cam-only")]
      : [
          translate("admin"),
          translate("crew"),
          translate("super-user"),
          translate("client-all"),
          translate("client-cam-only"),
        ];

  useEffect(() => {
    fetchRequests();
  }, [show]);

  useEffect(() => {
    const language = localStorage.getItem("language");
    let languageFullName;

    if (language === "EN") {
      languageFullName = "English";
    } else if (language === "DE") {
      languageFullName = "German";
    } else if (language === "NL") {
      languageFullName = "Dutch";
    }

    if (!modal.editUser) {
      if (userRole === "superuser") {
        setUserData({
          ...userData,
          role: "client-all",
          language: languageFullName,
        });

        if (projects.length === 1) {
          setTimeout(() => {
            selectProjectFunc(projects[0], 0);
          }, [1000]);
        }
      } else {
        setUserData({
          ...userData,
          role: "super-user",
          language: "German",
        });
      }
    }
  }, [modal]);

  const inputFields = [
    {
      label: translate("first-name"),
      name: "firstname",
      type: "text",
    },
    {
      label: translate("last-name"),
      name: "lastname",
      type: "text",
    },
    {
      label: translate("username"),
      name: "username",
      type: "text",
    },
    {
      label: translate("email-address"),
      name: "email",
      type: "email",
    },
    {
      label: translate("role"),
      name: "role",
      type: "dropDown",
      items: roleOptions,
      itemsValue:
        userRole === "superuser"
          ? ["ClientAll", "ClientCamOnly"]
          : ["Admin", "Crew", "SuperUser", "ClientAll", "ClientCamOnly"],
    },
    {
      label: translate("language"),
      name: "language",
      type: "dropDown",
      items: [translate("English"), translate("Dutch"), translate("German")],
      itemsValue: ["English", "Dutch", "German"],
    },
  ];

  const limit = 10000;

  const getData = async () => {
    await API.graphql(graphqlOperation(getUser, { id })).then(
      async ({ data }) => {
        await API.graphql(graphqlOperation(listProjects, { limit })).then(
          async (res1) => {
            if (userRole === "superuser") {
              const superUserProjects = projectList.map(
                (item) => item.projects
              );
              setProjects(superUserProjects);
            } else {
              setProjects(res1?.data?.listProjects?.items);
            }

            const editUserData = data?.getUser;
            let role;
            inputFields[4].itemsValue.filter((val, i) => {
              return val === editUserData.role
                ? (role = inputFields[4].items[i])
                : null;
            });
            setUserData({
              firstname: editUserData.firstname,
              lastname: editUserData.lastname,
              username: editUserData.username,
              email: editUserData.email,
              language: editUserData.language,
              role: editUserData.role
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/\s+/g, "-")
                .toLowerCase(),
              status: editUserData.status,
              groupName: editUserData.role,
            });
            setOldRole(role);
            setTimeout(() => {
              let index = [];
              const new123 = projects?.filter((val, i) =>
                editUserData?.projects?.items.filter((val1) =>
                  val1?.projectID === val.id
                    ? (index = [...index, { i, deleteID: val1.id }])
                    : null
                )
              );
              const selectedProjectsFinal = [...new123];
              index.forEach((val) => {
                selectedProjectsFinal[val.i].selected = true;
                selectedProjectsFinal[val.i].deleteID = val.deleteID;
              });
              setProjects(selectedProjectsFinal);
            }, 100);
          }
        );
      }
    );
  };
  getUserFunc.current = getData;

  useEffect(() => {
    if (editUser && id) {
      getUserFunc.current();
    }
  }, [editUser, id]);

  const fetchRequests = async () => {
    try {
      if (userRole === "superuser") {
        const userProjectsData = await API.graphql(
          graphqlOperation(listUsers, {
            filter: { username: { eq: Auth.user.username } },
            limit,
          })
        );
        const data = userProjectsData.data.listUsers.items[0]?.projects.items;
        const superUserProjects = data?.map((item) => item.projects);
        setProjects(superUserProjects);
      } else {
        const requestData = await API.graphql(
          graphqlOperation(listProjects, { limit })
        );
        const requests = requestData.data.listProjects.items;
        setProjects(requests);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleOnChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError("");
  };

  const addUserSubmit = async (e) => {
    e.preventDefault();
    let { username, role, email, language, firstname, lastname } = userData;
    username = username.toLowerCase().trim();
    setLoader(true);

    let usernameNotValid = /\s/.test(username);

    let emailValid =
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
    if (role && language && email) {
      if (role === inputFields[4].items[0].props.id) {
        role = inputFields[4].itemsValue[0];
      } else if (role === inputFields[4].items[1].props.id) {
        role = inputFields[4].itemsValue[1];
      } else if (role === inputFields[4].items[2].props.id) {
        role = inputFields[4].itemsValue[2];
      } else if (role === inputFields[4].items[3].props.id) {
        role = inputFields[4].itemsValue[3];
      } else if (role === inputFields[4].items[4].props.id) {
        role = inputFields[4].itemsValue[4];
      }

      if (language === inputFields[5].items[0].props.id) {
        language = inputFields[5].itemsValue[0];
      } else if (language === inputFields[5].items[1].props.id) {
        language = inputFields[5].itemsValue[1];
      } else if (language === inputFields[5].items[2].props.id) {
        language = inputFields[5].itemsValue[2];
      } else if (language === inputFields[5].items[3].props.id) {
        language = inputFields[5].itemsValue[3];
      }

      if (emailValid && !usernameNotValid) {
        try {
          const usersByEmail = await API.graphql(
            graphqlOperation(listUsers, {
              filter: { email: { eq: email } },
              limit,
            })
          );

          const usersByEmailData = usersByEmail.data.listUsers.items;
          if (usersByEmailData && usersByEmailData.length > 0) {
            setLoader(false);
            setExistingId(usersByEmailData[0].id);
            setExistingEditUser(usersByEmailData[0]);
            setErrorMessageExisting(true);
            setError("This email is already in use");
          } else if (usersByEmailData && usersByEmailData.length === 0) {
            try {
              createUserInCognito(
                username,
                email,
                "123456789",
                role,
                language
              ).then((res) => {
                if (JSON.stringify(res?.response?.data?.message)) {
                  setLoader(false);
                  setError(res?.response?.data?.message);
                } else {
                  addUserToGroup(username, email, role).then((res1) => {
                    if (JSON.stringify(res1?.response?.data?.message)) {
                      setLoader(false);

                      setError(res1?.response?.data?.message);
                    } else {
                      API.graphql(
                        graphqlOperation(createUser, {
                          input: {
                            username,
                            role,
                            email,
                            language,
                            firstname,
                            lastname,
                            notificationsEnabled: true,
                            archiving: false,
                            status: false,
                            createdBy: Auth.user.username,
                            pendingDeletion: false,
                          },
                        })
                      ).then(({ data }) => {
                        try {
                          events.log =
                            "A user " + userData.username + " has been created";
                          const event = { ...events };
                          API.graphql(
                            graphqlOperation(createEvent, { input: event })
                          );
                        } catch (err) {
                          throw err;
                        }
                        const userID = data?.createUser?.id;
                        selectedProjects.map((projectID) =>
                          API.graphql(
                            graphqlOperation(createUserProject, {
                              input: {
                                userID,
                                projectID,
                              },
                            })
                          )
                            .then(() => {
                              return <></>;
                            })
                            .catch(({ errors }) => setError(errors[0]?.message))
                        );
                        onCloseModal();
                      });
                    }
                  });
                }
              });
            } catch (err) {
              setLoader(false);
              setError(err);
            }
          }
        } catch (err) {
          setLoader(false);
          setError(err);
        }
      } else if (usernameNotValid) {
        setError("Username may not contain whitespace characters!");
        setLoader(false);
      } else {
        setError("You have entered an invalid email address!");
        setLoader(false);
      }
    } else {
      setError("Please fill up all fields");
      setLoader(false);
    }
  };
  const getIdByRoleAndEmail = (role, email) => {
    const matchedItem = currentItem?.find((item) => item.email === email);
    return matchedItem ? matchedItem.id : null; // Return the ID if found, else null
  };

  const resetTemporaryPassword = async () => {
    let { username, email, language, groupName } = userData;
    username = username.toLowerCase().trim();
    setLoader(true);
    try {
      resetUserCredentials(
        username,
        "123456789",
        email,
        groupName,
        language
      ).then((res) => {
        setLoader(false);
        if (JSON.stringify(res?.response?.data?.message)) {
          setError(res?.response?.data?.message);
        } else {
        }
      });
    } catch (err) {
      setLoader(false);
      setError(err);
    }
  };

  const editUserSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let { username, role, email, language, firstname, lastname } = userData;
    let OldRole;
    const updateLanguage = language?.props?.id ? language?.props?.id : language;

    if (oldRole.props.id === inputFields[4].items[0].props.id) {
      OldRole = inputFields[4].itemsValue[0];
    } else if (oldRole.props.id === inputFields[4].items[1].props.id) {
      OldRole = inputFields[4].itemsValue[1];
    } else if (oldRole.props.id === inputFields[4].items[2].props.id) {
      OldRole = inputFields[4].itemsValue[2];
    } else if (oldRole.props.id === inputFields[4].items[3].props.id) {
      OldRole = inputFields[4].itemsValue[3];
    } else if (oldRole.props.id === inputFields[4].items[4].props.id) {
      OldRole = inputFields[4].itemsValue[4];
    }

    if (role === inputFields[4].items[0].props.id) {
      role = inputFields[4].itemsValue[0];
    } else if (role === inputFields[4].items[1].props.id) {
      role = inputFields[4].itemsValue[1];
    } else if (role === inputFields[4].items[2].props.id) {
      role = inputFields[4].itemsValue[2];
    } else if (role === inputFields[4].items[3].props.id) {
      role = inputFields[4].itemsValue[3];
    } else if (role === inputFields[4].items[4].props.id) {
      role = inputFields[4].itemsValue[4];
    }

    if (updateLanguage === inputFields[5].items[0].props.id) {
      language = inputFields[5].itemsValue[0];
    } else if (updateLanguage === inputFields[5].items[1].props.id) {
      language = inputFields[5].itemsValue[1];
    } else if (updateLanguage === inputFields[5].items[2].props.id) {
      language = inputFields[5].itemsValue[2];
    } else if (updateLanguage === inputFields[5].items[3].props.id) {
      language = inputFields[5].itemsValue[3];
    }

    if (OldRole !== role) {
      addUserToGroup(username, email, role, OldRole).then((res) => {
        if (JSON.stringify(res?.response?.data?.message)) {
          setError(res?.response?.data?.message);
          setLoader(false);
        } else {
          removeUserFromGroup(userData.username, OldRole)
            .then(async (res1) => {
              if (JSON.stringify(res1?.response?.data?.message)) {
                setError(res1?.response?.data?.message);
                setLoader(false);
              } else {
                await API.graphql(
                  graphqlOperation(updateUser, {
                    input: {
                      username,
                      role,
                      email,
                      language,
                      firstname,
                      lastname,
                      id,
                      status: false,
                    },
                  })
                )
                  .then(() => {
                    onCloseModal();
                    try {
                      events.log =
                        "User role of " +
                        userData.username +
                        " has been changed to " +
                        role;
                      const event = { ...events };
                      API.graphql(
                        graphqlOperation(createEvent, { input: event })
                      );
                    } catch (err) {
                      throw err;
                    }
                  })
                  .catch((e1) => {
                    setLoader(false);
                    setError(e1.message);
                  });
              }
            })
            .catch((e2) => {
              setLoader(false);
              setError(e2.message);
            })
            .catch((e3) => {
              setLoader(false);
              setError(e3.message);
            });
        }
      });
    } else {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            username,
            role,
            email,
            language,
            firstname,
            lastname,
            id,
          },
        })
      )
        .then(() => {
          onCloseModal();
        })
        .catch((e4) => {
          setLoader(false);
          setError(e4.message);
        });
      onCloseModal();
    }
  };

  const editExistingUserSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    const { username, role, email, language, firstname, lastname } = userData;

    if (!existingId) {
      setError("User not found for the provided role and email");
      setLoader(false);
      return;
    }

    // Extract selected project IDs
    const selectedProjectIDs = projects
      .filter((project) => project.selected)
      .map((project) => project.id);

    try {
      // Update user details, including selected projects
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: existingId, // Use dynamic ID here
            username: existingEdisUser.username,
            firstname: existingEdisUser.firstname,
            lastname: existingEdisUser.lastname,
            email: existingEdisUser.email,
            role: existingEdisUser.role,
            language: existingEdisUser.language,
            recentProjects: selectedProjectIDs, // Pass selected projects
          },
        })
      );

      // Log the update event
      events.log = `User ${username} updated successfully`;
      await API.graphql(graphqlOperation(createEvent, { input: events }));

      onCloseModal();
    } catch (error) {
      setError(`Error updating user: ${error.message}`);
    } finally {
      setLoader(false);
    }
  };

  const onCloseModal = () => {
    setFieldsDisabled(false);
    setLoader(false);
    onHide();
    setError("");
    setSelectedProjects([]);
    setUserData({
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      role: "",
      language: "",
    });
    setSearchProject("");
    setShowAddToBaucamCloud(false);
    setIsErrorEdit(false);
  };

  const selectProjectFunc = (val, i) => {
    let updatedProjects = [...projects];
    if (editUser) {
      if (updatedProjects[i].selected) {
        API.graphql(
          graphqlOperation(deleteUserProject, {
            input: {
              id: val.deleteID,
            },
          })
        )
          .then(() => {
            updatedProjects[i].selected = false;
            setProjects(updatedProjects);
          })
          .catch(({ errors }) => setError(errors[0]?.message));
      } else {
        API.graphql(
          graphqlOperation(createUserProject, {
            input: {
              userID: id,
              projectID: val.id,
            },
          })
        )
          .then(({ data: { createUserProject } }) => {
            let deleteID = createUserProject.id;
            updatedProjects[i].selected = true;
            updatedProjects[i].deleteID = deleteID;
            setProjects(updatedProjects);
          })
          .catch(({ errors }) => setError(errors[0]?.message));
      }
    } else {
      if (updatedProjects[i].selected) {
        updatedProjects[i].selected = false;
        let newSelectedProjects = [...selectedProjects];
        _.remove(newSelectedProjects, function (value) {
          return value === updatedProjects[i].id;
        });
        setSelectedProjects(newSelectedProjects);
        setProjects(updatedProjects);
      } else {
        updatedProjects[i].selected = true;
        setSelectedProjects([...selectedProjects, val.id]);
        setProjects(updatedProjects);
      }
    }
  };

  useEffect(() => {
    if (error && error === "This email is already in use" && !isErrorEdit) {
      setIsErrorEdit(true);
    }
  }, [error]);

  const selectProjectExistingFunc = async (val, i) => {
    let updatedProjects = [...projects];

    const { username, role, email, language, firstname, lastname } = userData;

    // Get dynamic ID based on role and email

    if (isErrorEdit) {
      try {
        if (updatedProjects[i].selected) {
          // Remove project association
          await API.graphql(
            graphqlOperation(deleteUserProject, {
              input: { id: val.deleteID },
            })
          );
          updatedProjects[i].selected = false;
        } else {
          const { data } = await API.graphql(
            graphqlOperation(createUserProject, {
              input: {
                // userID: id || dynamicId, // Use appropriate ID
                userID: existingId,
                projectID: val.id,
              },
            })
          );
          updatedProjects[i].selected = true;
          updatedProjects[i].deleteID = data.createUserProject.id;
          setSelectedProjects([...selectedProjects, val.id]);
        }

        setProjects(updatedProjects);
      } catch (error) {
        setError(error.message);
      }
    } else {
      // Handle new user project selection
      if (updatedProjects[i].selected) {
        updatedProjects[i].selected = false;
        const newSelectedProjects = selectedProjects.filter(
          (projectID) => projectID !== val.id
        );
        setSelectedProjects(newSelectedProjects);
      } else {
        updatedProjects[i].selected = true;
        setSelectedProjects([...selectedProjects, val.id]);
      }
      setProjects(updatedProjects);
    }
  };

  const sortedProjectsExistingUser = projects
    ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));

  const sortedProjects = projects
    ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    .sort((a, b) => (a.selected === b.selected ? 0 : a.selected ? -1 : 1));

  return (
    <Modal className="add-user" show={show} onHide={() => onCloseModal()}>
      <div className="add-user__cross">
        <BiX
          key={2}
          color="#1181F2"
          size={30}
          onClick={() => onCloseModal()}
          className="add-user__cross-image"
        />
      </div>
      <h2>
        {editUser || isErrorEdit
          ? translate("edit-user")
          : translate("add-user2")}
      </h2>
      <span className="text-center text-danger">{!isErrorEdit && error}</span>
      {isErrorEdit && (
        <>
          {!showAddToBaucamCloud && (
            <p className="text-center text-danger mb-0 mt-2">
              {translate("used-email")}
            </p>
          )}
          {!showAddToBaucamCloud && (
            <p className="text-center text-danger mt-0">
              {translate("add-user-existing")}
            </p>
          )}
          {!showAddToBaucamCloud && (
            <div style={{ margin: "0 auto" }}>
              <ButtonSecondary
                title={translate("add-user2")}
                css={{ width: "150px", alignContent: "center" }}
                onClick={handleAddToBaucamCloud}
                disabled={loader}
              />
            </div>
          )}
        </>
      )}
      <form
        onSubmit={
          isErrorEdit
            ? editExistingUserSubmit
            : editUser
            ? editUserSubmit
            : addUserSubmit
        }
      >
        <Row>
          {inputFields.map((val, i) => (
            <Col md={6} sm={12} key={i + 1}>
              {val.type === "dropDown" ? (
                <DropdownInput
                  title={
                    val.name === "role" || val.name === "language"
                      ? userData[val.name]
                        ? val.name === "role"
                          ? translate(userData.role)
                          : translate(userData.language)
                        : translate("select")
                      : userData[val.name]
                      ? userData[val.name]
                      : translate("select")
                  }
                  name={val.name}
                  item={val.items}
                  label={val.label}
                  iconSize={20}
                  onSelect={(val1) => {
                    if (!fieldsDisabled) {
                      setUserData({
                        ...userData,
                        [val.name]: val1.props.id,
                      });
                    }
                  }}
                  disabled={fieldsDisabled}
                />
              ) : (
                <TextInput
                  key={i + 1}
                  type={val.type}
                  value={userData[val.name]}
                  onChange={(e) => !fieldsDisabled && handleOnChange(e)}
                  label={val.label}
                  name={val.name}
                  required={true}
                  disabled={
                    fieldsDisabled ||
                    (editUser &&
                      (val.name === "email" || val.name === "username"))
                  }
                />
              )}
            </Col>
          ))}
        </Row>
        <Row>
          <Col className="add-user__project-container" md={12} sm={12}>
            <label>{translate("projects")}</label>
            <div className="add-user__projects-sec">
              <span className="add-user__projects-sec-span">
                <div className="add-user__projects-sec-search-input">
                  <IoMdSearch className="add-user__projects-sec-search-input-icon" />
                  <input
                    type="text"
                    value={searchProject}
                    onChange={(e) => setSearchProject(e.target.value)}
                  />
                </div>
              </span>
              <div className="add-user__projects-box">
                {!isErrorEdit
                  ? sortedProjects?.map((project, i) => {
                      const name = project?.name?.toLowerCase();
                      if (name?.indexOf(searchProject.toLowerCase()) !== -1) {
                        return (
                          <div
                            onClick={() =>
                              error && error === "This email is already in use"
                                ? selectProjectExistingFunc(project, i)
                                : selectProjectFunc(project, i)
                            }
                            key={i}
                            className={
                              project?.selected
                                ? "add-user__project-item--active"
                                : "add-user__project-item"
                            }
                          >
                            {project?.name}
                          </div>
                        );
                      } else return <></>;
                    })
                  : sortedProjectsExistingUser?.map((project, i) => {
                      const name = project?.name?.toLowerCase();
                      if (name?.indexOf(searchProject.toLowerCase()) !== -1) {
                        return (
                          <div
                            onClick={() =>
                              error && error === "This email is already in use"
                                ? selectProjectExistingFunc(project, i)
                                : selectProjectFunc(project, i)
                            }
                            key={i}
                            className={
                              project?.selected
                                ? "add-user__project-item--active"
                                : "add-user__project-item"
                            }
                          >
                            {project?.name}
                          </div>
                        );
                      } else return <></>;
                    })}
              </div>
            </div>
          </Col>
        </Row>
        {fieldsDisabled && (
          <Row style={{ marginLeft: "45px" }}>
            <p className="mt-3 text-primary" style={{ fontSize: "16px" }}>
              {translate("selected-projects")}
            </p>
          </Row>
        )}
        {editUser && userData.status === false ? (
          <div>
            <p
              className="add-user__credentials"
              onClick={() => resetTemporaryPassword()}
            >
              {translate("Reset-temporary-password")}
            </p>
          </div>
        ) : null}
        {userRole === "superuser" && (
          <Row>
            <p className={`ml-4 mt-5`} style={{ fontSize: 16 }}>
              <b>{translate("client-all")}</b>{" "}
              {translate("cleint-all-description")} <br />
              <b>{translate("client-cam-only")}</b>{" "}
              {translate("client-cam-description")} <br />
              <b>{translate("note")}:</b> {translate("note-user")} <br />
            </p>
          </Row>
        )}
        <div className="add-user__btn-div">
          <ButtonPrimary
            title={isErrorEdit ? translate("save") : translate("create")}
            type="submit"
            backgroundColor="#fff"
            color="#000"
            disabled={
              loader ||
              (userRole === "superuser" &&
                !selectedProjects.length &&
                !editUser)
                ? true
                : false
            }
            loader={loader}
            loaderColour="#1181f2"
          />
        </div>
      </form>
    </Modal>
  );
};

AddEditUser.propTypes = {
  onHide: PropTypes.func,
  modal: PropTypes.object,
  projectList: PropTypes.array,
  userRole: PropTypes.string,
  currentItem: PropTypes.array,
};

export default AddEditUser;
