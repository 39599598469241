import { LOCALES } from "../locales";

export default {
  [LOCALES.DE]: {
    help: "Support",
    docs: "Hilfe",
    "no-project": "Kein Projekt",
    "go-back": "Zurück",
    "all-projects": "Alle Projekte",
    archive: "Archiv",
    "archiving-date": "Archivierungsdatum",
    add: "+ Hinzufügen",
    "copy-url": "URL kopieren",
    create: "+ Hinzufügen",
    delete: "Löschen",
    download: "Herunterladen",
    edit: "Bearbeiten",
    "edit-project": "Projekt bearbeiten",
    "edit-user": "Nutzer bearbeiten",
    end: "Beenden",
    "new-camera": "+ Neue Cam",
    "new-project": "+ Neues Projekt",
    no: "Nein",
    reload: "Neu laden",
    "Reset-temporary-password": "Temporäres Passwort zurücksetzen",
    save: "Speichern",
    off: "Aus",
    "num-of-cams": "Cams: ",
    company: "Firma: ",
    on: "An",
    yes: "Ja",
    "no-signal": "Kein Signal",
    "not-working": "Außer Betrieb",
    "on-pause": "Pausiert",
    "all-cameras": "Alle Cams",
    "all-cams": "Alle Cams",
    "all-images": "Alle Bilder",
    "by-date": "Nach Datum",
    "by-period": "Nach Zeitraum",
    project: "Projekten",
    "by-company": "Nach Unternehmen",
    company: "Unternehmen",
    from: "Von",
    "project-is-deleted": "Dieses Projekt wurde gelöscht oder ist beschädigt",
    "pending-deletion": "Das Löschen des Projekts ist in Arbeit.",
    notification: "Benachrichtigung Cam Offline",
    select: "Auswählen",
    "select-camera-view": "Cam auswählen: ",
    "select-date": "Datum auswählen",
    "selected-images": "Ausgewählte Bilder",
    disclaimer:
      " Wenn der Benutzer keinem Projekt zugeordnet ist, verschwindet der Benutzer automatisch aus Ihrer Benutzerliste.",
    "select-cam": "Cam auswählen",
    "select-company": "Unternehmen auswählen",
    to: "Bis",
    "monday-friday": "Montag bis Freitag",
    "monday-sunday": "Montag bis Sonntag",
    "add-user": "+ Nutzer hinzufügen",
    "add-user2": "Nutzer hinzufügen",
    "confirm-password": "Password bestätigen",
    "first-name": "Vorname",
    surname: "Nachname",
    "forgot-password": "PASSWORT VERGESSEN?",
    "last-name": "Name",
    login: "Anmelden",
    "login-in-service": "Login",
    password: "Passwort",
    "sign-up": "Registrierung",
    "wrong-password": "Passwörter stimmen nicht überein",
    "user-role": "Benutzer-Rolle",
    username: "Benutzername",
    "edit-profile": "Profil bearbeiten",
    "edit-company": "Unternehmen bearbeiten",
    language: "Sprache",
    "log-out": "Ausloggen",
    companies: "Unternehmen",
    "historical-archive": "Historisches Archiv",
    "log-file": "Logdatei",
    cams: "Cams",
    projects: "Projekte",
    users: "Nutzer",
    "number-of-cameras": "Anzahl Cams",
    "number-of-images": "Anzahl Bilder",
    "number-of-projects": "Anzahl Projekte",
    "assign-to-company": "Einem Unternehmen zuweisen",
    "camera-name": "Cam Name",
    "choose-cameras": "Cams auswählen",
    comment: "Kommentar",
    "company-name": "Firmenname",
    "gps-location": "GPS Standort",
    latitude: "Breitengrad",
    location: "Standort",
    longitude: "Längengrad",
    "add-company": "+ Unternehmen hinzufügen",
    "add-name": "Name hinzufügen",
    "create-new-camera": "Neue Cam hinzufügen",
    "create-new-project": "Neues Projekt erstellen",
    "delete-ask": "Wird gelöscht:",
    "edit-camera": "Cam bearbeiten",
    "project-name": "Projektname",
    "storage-size": "Größe",
    telephone: "Telefon",
    "client-all": "Nutzer",
    "client-cam-only": "Limitierter Nutzer",
    crew: "Crew",
    role: "Rolle",
    active: "Aktiv",
    disabled: "Deaktiviert",
    enabled: "Aktiviert",
    inactive: "Inaktiv",
    failure: "Fehler",
    "latest-picture-link": "Neuester Bild-Link",
    "copy-picture-link": "Bildlink kopieren",
    "view-image": "HiRes",
    "regenerate-credentials": "Referenzen erneuern",
    name: "Name",
    "external-camera": "Externe Kamera",
    status: "Status",
    blurring: "Blurring",
    "live-stream-enabled": "Live-stream aktiviert",
    timelapse: "Zeitraffer",
    "timelapse-enabled": "Zeitraffer aktiviert",
    "email-address": "E-Mail-Adresse",
    English: "Englisch",
    Dutch: "Niederländisch",
    German: "Deutsch",
    day: "TAG",
    "view-by": "Ansicht",
    week: "WOCHE",
    month: "MONAT",
    admin: "Admin",
    error: "Fehler",
    "user-logs": "Benutzer",
    success: "Erfolgreich",
    "user-logs": "Benutzer",
    "camera-logs": "Kameras",
    "project-logs": "Projekte",
    "day-dropdown": "Tag",
    "week-dropdown": "Woche",
    "month-dropdown": "Monat",
    "filter-by": "Filtern nach: ",
    "successfully-copied-image-link": "Bildlink in die Zwischenablage kopiert.",
    "no-image-availabe": "Es sind keine Bilder verfügbar!",
    "filter-by-name": "Nach Namen filtern",
    "filter-by-company": "Nach Unternehmen filtern",
    "timelapse-copy": "Link Tages-Zeitraffer kopieren",
    "timelapse-copied": "Tages-Zeitraffer kopiert",
    "filter-by-project": "Nach Projekt filtern",
    "add-customer": "Unternehmen hinzufügen",
    "load-more-images": "Weitere Bilder laden",
    website: "Webseite",
    "no-camera-project": "Keine Kamera mit diesem Projekt verbunden",
    "cannot-delete-company":
      "hat einige Projekte und kann nicht gelöscht werden",
    "no-archive-image": "Noch keine Bilder verfügbar",
    "end-project": "Möchten Sie dieses Projekt beenden?",
    "download-multiple-images": "Möchten Sie ausgewählte Bilder herunterladen?",
    "download-filtered-images": "Möchten Sie diese Bilder herunterladen?",
    "download-all-images": "Möchten Sie alle Bilder herunterladen?",
    "delete-multiple-images": "Möchten Sie diese Bilder löschen?",
    "delete-all-images": "Möchten Sie alle Bilder löschen?",
    "no-delete-image-permission":
      "Sie haben keine Berechtigung zum Löschen von Bildern",
    "only-50-images-delete":
      "Sie können maximal 50 Bilder gleichzeitig löschen",
    "send-download-link":
      "Ihr Download-Link wird in Kürze per E-Mail an {email} gesendet",
    email: "E-Mail",
    "selected-projects":
      "Wählen Sie Projekte aus und klicken Sie auf Speichern, um den Vorgang abzuschließen.",
    "wait-for-next-download":
      "Ihr vorheriger Download ist noch nicht abgeschlossen. Ein Downloadlink bereitzustellen kann abhängig von der Datenmenge bis zu 90 Minuten dauern.  Sobald Sie ihren Downloadlink per Mail erhalten haben, können Sie einen neuen Download anstoßen.",
    "no-projects": "Noch keine Projekte verfügbar",
    "used-email": "Diese E-Mail wird bereits verwendet",
    "add-user-existing":
      "Sie können Benutzer zu Ihrer BAU.CAMERA-Cloud hinzufügen",
    "no-cameras": "Keine Kameras verfügbar",
    "no-users": "Derzeit sind keine Nutzer vorhanden",
    "no-historical-archive": "Es gibt keine historischen Archive",
    "no-companies": "Derzeit gibt es keine Unternehmen",
    "blurring-enabled": "Blurring aktiviert",
    "blurring-pro-enabled": "Blurring pro aktiviert",
    "reset-password": "Passwort zurücksetzen",
    "back-to-login": "Zurück zur Anmeldung",
    "confirm-new-password": "Sie haben Ihr Passwort erfolgreich geändert",
    "check-email": "Bitte überprüfen Sie Ihre E-Mails",
    submit: "Einsenden",
    "new-password": "Neues Passwort",
    "change-password": "Passwort ändern",
    "super-user": "Hauptnutzer",
    "time-lapse-not-ready": "Zeitraffer nicht verfügbar",
    "time-lapse-not-created": "Zeitraffer anfordern",
    "time-lapse-not-requested": "Zeitraffer ist angefordert",
    "time-lapse-missing": "Der Zeitraffer fehlt",
    "time-lapse-in-progress": "Zeitraffer wird produziert",
    "time-lapse-available": "Zeitraffer ist verfügbar",
    "time-lapse-outdated": "Zeitraffer anfordern",
    "to-project-start": "zum Projektstart",
    "to-latest-image": "zum neuesten Bild",
    "cleint-all-description":
      "hat Zugang zum Archiv der Kamera eines zugewiesenen Projekts",
    "client-cam-description":
      "hat keinen Zugang zum Archiv der Kamera eines zugewiesenen Projekts",
    "note-user":
      "Wenn ein Nutzer keinem Projekt zugeordnet ist, verschwindet er automatisch aus Ihrer Benutzerliste.",
    "date-range": "Datumsbereich",
    "exclude-weekend": "Wochenende ausschließen",
    note: "Hinweis",
    temperature: "Temperatur",
    "wind-speed": "Windgeschwindigkeit",
    "cloud-cover": "Bedeckungsgrad",
    precipitation: "Niederschlag",
    "test-mode": "Testmodus",
    "no-last-view-projects":
      "Im Moment gibt es keine zuletzt angesehenen Projekte.",
    "last-viewed-projects": "Aktuelle Projekte",
    Sunny: "Sonnig",
    "Mostly sunny": "Meist sonnig",
    "Partly sunny": "Teilweise sonnig",
    "Intermittent clouds": "Heiter bis wolkig",
    "Hazy sunshine": "Diesig mit Sonnenschein",
    "Mostly cloudy": "Überwiegend bewölkt",
    Cloudy: "Bewölkt",
    "Dreary (overcast)": "Bedeckt",
    Fog: "nebel",
    Showers: "Schauer",
    "Mostly cloudy w/ Showers": "Überwiegend bewölkt mit Schauern",
    "Partly cunny w/ Showers": "Teilweise sonnig mit Schauern",
    "T-Storms": "Gewitter",
    "Mostly cloudy w/ T-Storms": "Überwiegend bewölkt mit Gewittern",
    "Partly sunny w/ T-Storms": "Teilweise sonnig mit Gewittern",
    Rain: "Regen",
    Flurries: "Schneeschauer",
    "Mostly cloudy w/ Flurries": "Überwiegend bewölkt mit Schneeschauern",
    "Partly sunny w/ Flurries": "Teilweise sonnig mit Schneeschauern",
    Snow: "Schnee",
    "Mostly cloudy w/ Snow": "Überwiegend bewölkt mit Schnee",
    Ice: "Eis",
    Sleet: "Schneeregen",
    "Freezing rain": "Eisregen",
    "Rain and snow": "Regen und Schnee",
    Hot: "Heiß",
    Cold: "Kalt",
    Windy: "Windig",
    Clear: "Klar",
    "Mostly clear": "Meist klar",
    "Partly cloudy": "Teilweise bewölkt”",
    "Hazy moonlight": "Diesiger Mondschein",
    "Partly cloudy w/ Showers": "Teilweise bewölkt mit Schauern",
    "Partly cloudy w/ T-Storms": "Teilweise bewölkt mit Gewittern",
    "Light rain": "Leichter Regen",
    Drizzle: "Nieselregen",
    "Dense fog": "Dichter Nebel",
    "Light rain shower": "Leichter Schauer",
    "Some clouds": "Einige Wolken",
    "Light snow": "Leichter Schnee",
    "ready-to-download": "Zum Download bereit",
    "requested-download": "Herunterladen angefordert",
    "download-in-progress": "Herunterladen läuft",
    "download-failed": "Herunterladen fehlgeschlagen",
    "delete-selected-archive": "Ausgewählte Archive löschen",
    "delete-selected-company": "Ausgewählte Unternahmen löschen",
    "user-guide": "Benutzeranleitung",
  },
};
